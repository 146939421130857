/*============================================================================================= 
	Company    : PT Web Architect Technology - webarq.com
	Document   : Stylesheet
	Author     : Harlan
==============================================================================================*/

@import "sass-lib";

body {
  color: #333;
  font-size: 12px;
  font-family: $f_monts;
  position: relative;
  overflow-x: hidden;
}
img {
  border: 0;
  max-width: 100%;
  height: auto;
}
p {
  margin: 1px 0 15px;
  line-height: 20px;
}
input,
textarea,
select {
  font-size: 12px;
  border: none;
  border: 1px solid #d8d8d8;
  padding: 10px;
  margin: 0;
  color: #666;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
input[type="checkbox"],
input[type="radio"] {
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: none;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
h1 {
  font-size: 32px;
}
h2 {
  font-size: 28px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 20px;
}
h5 {
  font-size: 16px;
}
h6 {
  font-size: 14px;
}
/* structure 
----------------------------------------------------------------------------------------------*/
section {
  position: relative;
}
.wrapper {
  width: 1600px;
  margin: 0 auto;
  &.w126 {
    width: 1260px;
  }
  &.w130 {
    width: 1300px;
  }
  &.det_des {
    width: 1200px;
  }
}
picture {
  display: block;
}

/* global
----------------------------------------------------------------------------------------------*/
.after_clear:after,
.wrapper:after,
section:after {
  clear: both;
  display: block;
  content: " ";
}
.before_clear:before {
  clear: both;
  display: block;
  content: " ";
}
.a-left {
  text-align: left;
}
.a-right {
  text-align: right;
}
.a-center {
  text-align: center;
}
.italic {
  font-style: italic;
}
.bold {
  font-weight: bold;
}
.regular {
  font-weight: lighter;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.clear {
  clear: both;
}

.btn_round {
  display: inline-block;
  padding: 0 25px;
  line-height: 56px;
  min-width: 200px;
  @include boxSizing(border-box);
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  @include borderRadius(30px);
  cursor: pointer;
  letter-spacing: 2px;
  position: relative;
  &:after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: block;
    content: "";
    background: #fff;
    @include borderRadius(30px);
    z-index: 1;
    @include transform(scale(0.5));
    opacity: 0;
    @include transition(all ease-out 0.25s);
  }
  &:before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: block;
    content: "";
    border: 2px solid #fff;
    @include borderRadius(30px);
    z-index: 1;
    opacity: 1;
    @include transition(all ease-out 0.25s);
    @include transform(scale(1));
  }
  span {
    z-index: 2;
    @include transition(all ease-out 0.25s);
    color: #fff;
    display: inline-block;
    position: relative;
  }

  &.orn {
    &:after {
      background: $c_adv;
      @include transform(scale(1));
      opacity: 1;
    }
    span {
      color: #fff;
    }
    &:before {
      border-color: $c_adv;
      @include transform(scale(1.2));
      opacity: 0;
    }
    &:hover {
      &:after {
        @include transform(scale(0.5));
        opacity: 0;
      }
      span {
        color: $c_adv;
      }
      &:before {
        @include transform(scale(1));
        opacity: 1;
      }
    }
  }
  &.ico {
    i {
      display: inline-block;
      width: 18px;
      height: 18px;
      background: url("../images/material/btn_plus.png") no-repeat top center;
      vertical-align: middle;
      margin-right: 5px;
      position: relative;
      z-index: 2;
    }
    &:hover {
      i {
        background-position: bottom center !important;
      }
    }
  }

  &.list_orn {
    &:after {
      background: $c_adv;
      @include transform(scale(0.5));
      opacity: 0;
    }
    span {
      color: $c_adv;
    }
    &:before {
      border-color: $c_adv;
      @include transform(scale(1));
      opacity: 1;
    }
    &:hover {
      &:after {
        @include transform(scale(1));
        opacity: 1;
      }
      span {
        color: #fff;
      }
      &:before {
        @include transform(scale(1.2));
        opacity: 0;
      }
    }
  }
  &.little {
    line-height: 43px;
    min-width: auto;
    padding: 0 25px;
    &:before {
      border-width: 1px;
    }
    &:before,
    &:after {
      @include borderRadius(22px);
    }
  }

  &:hover {
    color: #fff;
    &:after {
      opacity: 1;
      @include transform(scale(1));
      @include transition(all ease-out 0.25s);
    }
    &:before {
      @include transform(scale(1.2));
      opacity: 0;
      @include transition(all ease-out 0.25s);
    }
    span {
      color: $c_333;
      @include transition(all ease-out 0.25s);
    }
    i {
      background-position: bottom center !important;
    }
  }
}

input[type="submit"].btn_round {
  cursor: pointer;
  display: inline-block;
  &.orn {
    background: $c_adv;
    color: #fff;
    &:hover {
      background: #962d08;
    }
  }
}

.ui-datepicker {
  z-index: 5 !important;
  .ui-widget-header {
  }
}
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  background: rgba(180, 51, 2, 0.5);
  color: #fff;
  border-color: $c_adv;
}

/* header
----------------------------------------------------------------------------------------------*/
header {
  position: fixed;
  left: 0;
  right: 0;
  display: block;
  z-index: 9;
  text-align: center;
  padding: 30px 0;
  border-bottom: 1px solid #d8d8d8;
  @include transition(padding 0.3s ease-out);
  background: #fff;
  .lf_head {
    float: left;
    @extend .after_clear;
    margin-top: 16px;
    position: relative;
    z-index: 3;
    .burger {
      float: left;
      padding-right: 20px;
      border-right: 1px solid $c_333;
      margin-right: 20px;
      .ico_brger {
        display: block;
        position: relative;
        width: 35px;
        height: 23px;
        span {
          height: 3px;
          display: block;
          width: 50%;
          background: $c_333;
          @include transition(all 0.3s ease-in-out);
          @include transform(rotate(0deg));
          font-size: 0;
          position: absolute;

          &:nth-child(even) {
            left: 50%;
          }
          &:nth-child(odd) {
            left: 0;
          }

          &:nth-child(1),
          &:nth-child(2) {
            top: 0;
          }
          &:nth-child(3),
          &:nth-child(4) {
            top: 0;
            bottom: 0;
            margin: auto;
          }
          &:nth-child(5),
          &:nth-child(6) {
            top: auto;
            bottom: 0;
          }
          &:nth-child(2) {
            width: 0;
          }
        }
        &:hover {
          span {
            background: $c_adv;
            @include transition(all 0.3s ease-in-out);
            &:nth-child(2) {
              width: 50%;
            }
            &:nth-child(4) {
              width: 0;
            }
          }
        }
        &.act {
          span {
            @include transition(all 0.3s ease-in-out);
          }
          span:nth-child(1),
          span:nth-child(6) {
            @include transform(rotate(45deg));
          }
          span:nth-child(2),
          span:nth-child(5) {
            @include transform(rotate(-45deg));
          }
          span:nth-child(1) {
            left: 3px !important;
            top: 4px !important;
          }

          span:nth-child(2) {
            left: calc(50% - 3px) !important;
            top: 4px !important;
            width: 50%;
          }
          span:nth-child(3) {
            left: -50% !important;
            opacity: 0 !important;
          }
          span:nth-child(4) {
            left: 100% !important;
            opacity: 0 !important;
          }
          span:nth-child(5) {
            left: 3px !important;
            top: 16px !important;
          }
          span:nth-child(6) {
            left: calc(50% - 3px) !important;
            top: 16px !important;
          }
          &:hover {
            span {
              &:nth-child(2),
              &:nth-child(4) {
                width: 50%;
              }
            }
          }
        }
      }
    }
    .ico_src {
      float: left;
      padding-right: 20px;
      border-right: 1px solid $c_333;
      margin-right: 20px;
      .btn_src {
        display: block;
        width: 23px;
        height: 22px;
        background: url("../images/material/src_head.png") no-repeat right top;
        cursor: pointer;
      }
      &:hover {
        .btn_src {
          background-position: bottom left !important;
        }
      }
    }
    .lang {
      float: left;
      position: relative;
      .act {
        font-size: 14px;
        color: $c_333;
        line-height: 23px;
        padding-right: 20px;
        text-transform: uppercase;
        font-weight: 300;
        cursor: pointer;
        position: relative;
        &:after {
          content: "";
          width: 10px;
          height: 6px;
          background: url("../images/material/arr_lang.png") no-repeat right
            bottom;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
      .drop {
        padding: 10px 10px 10px 2px;
        position: absolute;
        top: 100%;
        display: none;
        a {
          font-size: 14px;
          color: $c_333;
          text-transform: uppercase;
          font-weight: 300;
        }
      }
      &.idr {
        margin-left: 15px;
        .drop {
          background: #fff;
          padding: 8px 5px 10px;
          left: -10px;
          right: 5px;
          @include boxShadow(0 3px 12px -3px rgba(0, 0, 0, 0.4));
          a {
            padding: 5px 0;
            display: inline-block;
            @include transition(all 0.2s ease-out);
            &:hover {
              @include transition(all 0.2s ease-out);
              font-weight: 500;
            }
          }
        }
      }
    }
  }
  .logo {
    width: 440px;
    float: left;
    margin-right: 140px;
    a {
      img {
        display: none;
        &.stay {
          display: block;
        }
      }
    }
  }
  .rh_head {
    float: right;
    @extend .after_clear;
    position: relative;
    z-index: 3;
    nav {
      @extend .after_clear;
      float: left;
      margin-top: 5px;
      a {
        float: left;
        color: $c_333;
        font-size: 16px;
        font-weight: 500;
        padding: 10px;
        text-transform: uppercase;
        display: block;
        margin: 6px 0 0 25px;
        &:first-child {
          margin-left: 0;
        }
        &.btn_round {
          padding: 0 25px;
          margin-top: 0;
          &:after {
            background: $c_adv;
          }
          &:before {
            border-color: $c_333;
          }
          span {
            color: $c_333;
          }
        }
        &:hover {
          color: $c_adv;
          @include transition(color ease-out 0.25s);
          &.btn_round {
            span {
              color: #fff;
            }
          }
        }
      }
    }
    &.ind {
      .logo {
        margin-right: 40px;
      }
    }
  }
  &.home {
    padding-top: 50px;
    background: transparent;
    border-bottom-color: transparent;
    .lf_head {
      .burger {
        border-right: 1px solid #fff;
        .ico_brger {
          span {
            background: #fff;
            &:nth-child(even) {
              left: 50%;
            }
            &:nth-child(odd) {
              left: 0;
            }

            &:nth-child(1),
            &:nth-child(2) {
              top: 0;
            }
            &:nth-child(3),
            &:nth-child(4) {
              top: 0;
              bottom: 0;
              margin: auto;
            }
            &:nth-child(5),
            &:nth-child(6) {
              top: auto;
              bottom: 0;
            }
            /*&:nth-child(2) {opacity: 0;}*/
          }
          &:hover {
            span {
              background: $c_adv;
            }
          }
        }
      }
      .ico_src {
        border-color: #fff;
        .btn_src {
          display: block;
          width: 23px;
          height: 22px;
          background-position: left top;
          cursor: pointer;
        }
        &:hover {
          .btn_src {
            background-position: bottom left !important;
          }
        }
      }
      .lang {
        .act {
          color: #fff;
          &:after {
            background: url("../images/material/arr_lang.png") no-repeat right
              top;
          }
        }
        .drop {
          a {
            font-size: 14px;
            color: #fff;
            text-transform: uppercase;
          }
        }
        &.idr {
          .drop {
            a {
              color: $c_333;
            }
          }
        }
      }
    }
    .logo {
      a {
        img {
          display: block;
          &.stay {
            display: none;
          }
        }
      }
    }
    .rh_head {
      nav {
        a {
          color: #fff;
          @include transition(color ease-out 0.25s);
          &.btn_round {
            &:after {
              background: #fff;
            }
            &:before {
              border-color: #fff;
            }
            span {
              color: #fff;
            }
          }
          &:hover {
            color: $c_adv;
            @include transition(color ease-out 0.25s);
            &.btn_round {
              span {
                color: $c_adv;
              }
            }
          }
        }
      }
    }
    .bg_white {
      display: block;
    }
  }
  &.float {
    padding-top: 30px;
    @include transition(padding 0.3s ease-out);
    .lf_head {
      .burger {
        border-right: 1px solid $c_333;
        .ico_brger {
          span {
            background: $c_333;
          }
          &:hover {
            span {
              background: $c_adv;
            }
          }
        }
      }
      .ico_src {
        border-color: $c_333;
        .btn_src {
          background-position: right top !important;
        }
        &:hover {
          .btn_src {
            background-position: bottom left !important;
          }
        }
      }
      .lang {
        .act {
          color: $c_333;
          &:after {
            background: url("../images/material/arr_lang.png") no-repeat right
              bottom;
          }
        }
        .drop {
          a {
            color: $c_333;
          }
        }
      }
    }
    .logo {
      width: 440px;
      float: left;
      a {
        img {
          display: none;
          &.stay {
            display: block;
          }
        }
      }
    }
    .rh_head {
      nav {
        a {
          color: $c_333;
          @include transition(color ease-out 0.25s);
          &.btn_round {
            &:after {
              background: $c_adv;
            }
            &:before {
              border-color: $c_333;
            }
            span {
              color: $c_333;
            }
          }
          &:hover {
            color: $c_adv;
            @include transition(color ease-out 0.25s);
            &.btn_round {
              span {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
  .bg_white {
    background: #fff;
    position: fixed;
    left: 0;
    right: 0;
    display: block;
    z-index: 2;
    height: 115px;
    top: 0;
    height: 0;
    border-bottom: 1px solid #d8d8d8;
    display: none;
    @include boxSizing(border-box);
    opacity: 0;
  }
}
.wrap_drop,
.wrap_sideMn {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  @include boxSizing(border-box);
  display: none;
}
.overlay_src {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  display: block;
}

.pop_src {
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 150px;
  z-index: 3;
  padding: 46px 0;
  @include boxSizing(border-box);
  form {
    position: relative;
    @include placeholder {
      color: #dedede;
    }
    input[type="text"] {
      border: none;
      font-size: 50px;
      color: #dedede;
      width: 100%;
      @include boxSizing(border-box);
      padding: 0;
    }
    input[type="submit"] {
      position: absolute;
      right: 0;
      padding: 0;
      top: 0;
      bottom: 0;
      width: 50px;
      cursor: pointer;
      background: url("../images/material/src_drop.png") no-repeat center;
      border: none;
    }
  }
}

.wrap_sideMn {
  display: none;
  z-index: 8;
}
.overlay_mn {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  background: url("../images/material/overlay_side.jpg") no-repeat center;
  z-index: 2;
  display: block;
}

.mnu_wrap {
  position: absolute;
  top: 55%;
  @include transform(translateY(-55%));
  left: 0;
  right: 0;
  z-index: 5;
  nav {
    display: block;
    width: auto;
    ul {
      li {
        margin-bottom: 35px;
        a {
          font-family: $f_lato;
          font-size: 65px;
          color: #fff;
          font-weight: 700;
          opacity: 0.25;
          @include transition(all ease-out 0.25s);
        }
        .sub {
          display: none;
          position: absolute;
          left: 530px;
          top: 0;
          padding-left: 285px;
          padding-top: 30px;
          width: 400px;
          z-index: 6;
          ul {
            li {
              margin-bottom: 15px;
              a {
                font-size: 24px;
                font-weight: 400;
                opacity: 0.5;
                &:hover {
                  font-weight: 700;
                  opacity: 1;
                }
              }
            }
          }
          .line {
            display: block;
            position: absolute;
            top: 40px;
            width: 0;
            height: 1px;
            background: #fff;
            left: 0;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          > a {
            @include transition(all ease-out 0.25s);
            opacity: 1;
          }
        }
      }
    }
  }
  .sign {
    display: none;
  }
  .bhs {
    display: none;
  }
}

/* homepage
----------------------------------------------------------------------------------------------*/
.slideHome {
  position: relative;
  overflow: hidden;
  .slider {
    .slide {
      .images {
        position: relative;
        overflow: hidden;
        img {
          display: block;
          width: 100%;
        }
        &:after {
          content: "";
          position: absolute;
          display: block; /*background: url('../images/material/bg_opacity_slide.png') no-repeat bottom center;*/
          left: 0;
          right: 0;
          margin: auto;
          top: 0;
          bottom: 0;
          background-size: 100%;
          background: rgba(0, 0, 0, 0.2)
            url("../images/material/top_bg_banner.png") no-repeat top center;
        }
      }
      .capt_slide {
        position: absolute;
        z-index: 2;
        top: 38%;
        left: 0;
        right: 0;
        @include transform(translateY(0%));
        h6 {
          color: #fff;
          max-width: 800px;
          font-family: $f_nova;
          font-size: 20px;
          text-transform: uppercase;
          margin-bottom: 15px;
        }
        h1 {
          color: #fff;
          max-width: 800px;
          font-size: 60px;
          line-height: 72px;
          margin-bottom: 40px;
        }
      }
    }
    .slick-arrow {
      position: absolute;
    }
    .slick-dots {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      width: 1600px;
      margin: auto;
      @include transform(translateY(-50%));
      text-align: right;
      li {
        display: block;
        margin-bottom: 10px;
        button {
          @include transition(all ease-out 0.2s);
          border: none;
          background: #fff;
          font-size: 0;
          cursor: pointer;
          width: 42px;
          height: 5px;
          opacity: 0.4;
        }
        &.slick-active,
        &:hover {
          button {
            opacity: 1;
            @include transition(all ease-out 0.2s);
          }
        }
      }
    }
  }
  &.slideDestination {
    .slider {
      .slide {
        .capt_slide {
          top: 50%;
          left: 0;
          right: 0;
          @include transform(translateY(-50%));
        }
      }
    }
  }
  &.contentslider {
    width: 1600px;
    margin: 0 auto;
    margin-bottom: 100px;
    .slider {
      .slick-dots {
        width: 1400px;
      }
    }
  }
}
.bannerslide {
  .slideHome {
    .slider {
      .slide {
        .images {
          &:after {
            background: none;
          }
        }
      }
    }
  }
}
.pack_detail {
  .wrapper.w126 {
    width: 1470px;
  }
}
.sec_journey {
  padding: 20px 0;
  border-bottom: 1px solid #d8d8d8;
  .left {
    margin-top: 11px;
    h6 {
      font-size: 20px;
      color: #a0a0a0;
      font-family: $f_nova;
      span {
        display: block;
        font-size: 18.68px;
        color: $c_adv;
      }
    }
  }
  .right {
    @extend.after_clear;
  }
  .select_journey {
    float: left;
    @extend.after_clear;
    margin-top: 1px;
    .sel {
      float: left;
      select {
        width: 315px;
        padding: 20px 40px;
        font-size: 14px;
        color: $c_999;
        background: #fff url("../images/material/arr_select.png") no-repeat 90%
          center;
        border-right: none;
      }
      &:nth-of-type(1) {
        select {
          @include borderRadius(29px 0 0 29px);
        }
      }
      &:nth-of-type(3) {
        select {
          @include borderRadius(0 29px 29px 0);
          border-right: 1px solid #d8d8d8;
        }
      }
    }
  }
  .btn_round {
    float: left;
    margin-left: 40px;
  }
}

.txt_top {
  width: 940px;
  margin: 0 auto;
  h6 {
    font-size: 14px;
    text-transform: uppercase;
    color: $c_adv;
    font-family: $f_nova;
    margin-bottom: 20px;
    letter-spacing: 2px;
  }
  h1 {
    font-size: 50px;
    color: $c_333;
    margin-bottom: 25px;
  }
  p {
    font-size: 18px;
    line-height: 30px;
    color: $c_666;
    margin-bottom: 50px;
    font-family: $f_lato;
  }
  &.center {
    text-align: center;
  }
}
.listing {
  @extend .after_clear;
  .box {
    float: left;
  }
  .item {
    float: left;
  }
}

.why_list {
  width: 1140px;
  margin: 0 auto;
  .box {
    width: 340px;
    margin-right: 60px;
    text-align: center;
    margin-bottom: 60px;
    min-height: 215px;
    &:nth-of-type(3n) {
      margin-right: 0;
    }
    .ico {
      margin-bottom: 20px;
      img {
        display: block;
        margin: 0 auto;
        @include transition(all ease-out 0.3s);
      }
    }
    .dec {
      h3 {
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 1.6px;
        line-height: 24px;
        margin-bottom: 20px;
      }
      p {
        font-size: 16px;
        color: $c_999;
        margin-bottom: 0;
        font-family: $f_lato;
      }
    }
    &:hover {
      .ico {
        img {
          @include transform(scale(1.15));
          @include transition(all ease-out 0.3s);
        }
      }
    }
  }
}

.home_about {
  padding: 90px 0 70px;
  position: relative;
  .images {
    margin-top: 70px;
  }
}
.home_why {
  padding: 70px 0 25px;
  background: #f2f2f2;
}

.home_service {
  padding: 85px 0 150px;
  .nav_servive {
    text-align: center;
    display: block;
    margin: 55px 0;
    .list {
      display: inline-block;
      border-bottom: 1px solid #d8d8d8;
      a {
        display: inline-block;
        font-size: 13px;
        color: $c_999;
        padding: 10px 20px;
        font-size: 16px;
        margin-right: 15px;
        position: relative;
        @include transition(color ease-out 0s);
        &.active,
        &:hover {
          color: $c_333;
          @include transition(color ease-out 0.25s);
          &:after {
            width: 100%;
            @include transition(all linear 0.25s);
          }
        }
        &:last-of-type {
          margin-right: 0;
        }
        &:after {
          content: "";
          height: 4px;
          background: $c_adv;
          left: 0;
          right: 0;
          width: 0;
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          bottom: -5px;
          @include transition(all linear 0.25s);
        }
      }
    }
  }
  .slider_service,
  .list_desti {
    .slide {
      margin-right: 20px !important;
    }
    &.slide_Package {
      .item {
        margin-right: 20px !important;
      }
    }
  }
  .content_service {
    .service {
      display: none;
    }
  }
}
.link_btn {
  margin-top: 50px;
  &.center {
    text-align: center;
  }
}
.slider_service,
.list_desti {
  .slide {
    width: 385px;
    margin-right: 20px;
    .img {
      position: relative;
      overflow: hidden;
      img {
        display: block;
      }
      .hov_see {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.35);
        opacity: 0;
        @include transform(scale(1.15));
        display: block;
        @include transition(all ease-out 0.25s);
        .btn_round {
          position: absolute;
          left: 50%;
          width: 175px;
          top: 50%;
          @include transform(translate(-50%, -50%));
          display: block;
          &:hover {
            span {
              color: $c_adv;
            }
          }
        }
      }
    }
    .ket {
      padding-right: 20px;
      margin-top: 20px;
      h3 {
        font-size: 16px;
        color: $c_333;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-bottom: 6px;
      }
      p {
        font-size: 16px;
        color: $c_999;
        font-family: $f_lato;
        margin-bottom: 0;
      }
    }
    &:hover {
      .img {
        .hov_see {
          opacity: 1;
          @include transform(scale(1));
          @include transition(all ease-out 0.35s);
        }
      }
    }
  }
  .slick-arrow {
    position: absolute;
    width: 58px;
    height: 10px;
    background: url("../images/material/arr_slider.png") no-repeat top center;
    z-index: 3;
    border: none;
    font-size: 0;
    display: block;
    cursor: pointer;
    top: 40%;
    bottom: auto;
    margin: auto;
    &:after {
      left: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      background: url("../images/material/arr_slider.png") no-repeat bottom
        right;
      content: " ";
      @include transition(width linear 0.2s);
      display: block;
      right: 0;
      width: 0;
    }
    &:hover {
      &:after {
        @include transition(width linear 0.2s);
        width: 100%;
      }
    }
    &.slick-prev {
      left: -95px;
      right: auto;
    }
    &.slick-next {
      right: -95px;
      left: auto;
      @include transform(rotate(180deg));
    }
  }
}

.slider_service.slide_Package {
  .item {
    width: 385px;
    margin-right: 20px;
    float: left;
    .img {
      img {
        width: 100%;
        display: block;
      }
    }
    &:nth-of-type(4n) {
      margin-right: 20px;
    }
  }
}

.home_travel {
  &:after {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: block;
    z-index: 1;
  }
  > img {
    display: block;
    width: 100%;
  }
  .text_travel {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 870px;
    text-align: center;
    z-index: 2;
    top: 50%;
    @include transform(translateY(-50%));
    h3 {
      font-size: 31px;
      line-height: 46px;
      color: #fff;
    }
    .link_btn {
      .btn_round {
        &.list_orn {
          &:before {
            border-color: #fff;
          }
          &:after {
            background: #fff;
          }
          &:hover {
            span {
              color: #b43302;
            }
          }
          span {
            color: #fff;
            i {
              font-style: normal;
              display: inline-block;
              margin-right: 5px;
            }
          }
        }
      }
      > span {
        font-size: 14px;
        display: inline-block;
        margin: 0 15px;
        color: #fff;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }
}

.home_testimonial {
  padding-bottom: 100px;
  .slider_testimo {
    width: 940px;
    margin: 0 auto;
    position: relative;
    .slide {
      text-align: center;
      h3 {
        font-size: 20px;
        line-height: 28px;
        color: #b43302;
        margin-bottom: 20px;
        font-weight: 600;
      }
      p {
        font-size: 16px;
        line-height: 30px;
      }
      .info {
        @extend .after_clear;
        padding: 20px 0;
        border-top: 1px solid #ccc;
        margin-top: 30px;
        .right {
          div {
            display: inline-block;
            margin-left: 20px;
            font-size: 16px;
            color: #b43302;
            font-weight: 500;
            &.dte {
              padding-left: 22px;
              position: relative;
              &:after {
                content: "";
                left: 0;
                width: 6px;
                height: 6px;
                @include borderRadius(50%);
                background: #999;
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
              }
            }
          }
        }
        .left {
          .star {
            span {
              display: inline-block;
              vertical-align: middle;
              width: 20px;
              height: 17px;
              background: url("../images/material/rating_ico.png") no-repeat top
                center;
              background-size: 19px;
              &.act {
                background-position: bottom center;
              }
            }
          }
        }
      }
    }
    .slick-arrow {
      border: none;
      font-size: 0;
      padding: 0;
      position: relative;
      cursor: pointer;
      position: absolute;
      top: 40%;
      bottom: auto;
      margin: auto;
      &.slick-next,
      &.slick-prev {
        width: 58px;
        height: 10px;
        background: url("../images/material/arr_pagging.png") no-repeat top
          center;
        &:after {
          width: 0px;
          height: 10px;
          background: url("../images/material/arr_pagging.png") no-repeat bottom
            center;
          content: "";
          position: absolute;
          right: 0;
          @include transition(width linear 0.2s);
          top: 0;
        }
        &:hover {
          &:after {
            @include transition(width linear 0.2s);
            width: 58px;
          }
        }
      }
      &.slick-prev {
        margin-right: 10px;
        left: -120px;
        right: auto;
      }
      &.slick-next {
        @include transform(rotate(180deg));
        margin-left: 10px;
        left: auto;
        right: -120px;
      }
      &:hover,
      &.active {
        @include transition(color ease-out 0.25s);
        color: $c_333;
      }
    }
  }
}
/* middle
----------------------------------------------------------------------------------------------*/
.middle {
  padding: 115px 0 60px;
  .top_pg {
    .txt_top {
      padding: 130px 0 75px;
      &.pb_50 {
        padding-bottom: 20px;
      }
    }
    &.list_box {
      .txt_top {
        padding: 80px 0 30px;
      }
    }
  }
  .txt_top {
    padding: 100px 0 75px;
    p {
      margin-bottom: 30px;
    }
  }
  &.no_padding {
    padding-top: 0;
  }
}
.breadcrumb {
  background: #f3f3f3;
  height: 60px;
  @include boxSizing(border-box);
  padding: 20px 170px;
  margin-bottom: 50px;
  .note {
    margin: 0;
    font-size: 14px;
    color: $c_999;
    text-transform: uppercase;
    font-family: $f_lato;
    line-height: 20px;
    span {
      font-weight: 700;
    }
  }
  > a {
    font-size: 14px;
    display: inline-block;
    position: relative;
    text-transform: uppercase;
    font-family: $f_lato;
    line-height: 20px;
    color: $c_999;
    @include transition(color 0.25s ease-out);
    &:after {
      content: ">";
      display: inline-block;
      font-family: $f_lato;
      line-height: 20px;
      margin: 0 10px 0 12px;
      color: $c_999;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
    &:hover,
    &.active {
      color: $c_666;
      @include transition(color 0.25s ease-out);
    }
    &.active {
      font-weight: 700;
    }
  }
}
.banner_top {
  .images {
    position: relative;
    img {
      display: block;
      width: 100%;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background: rgba(0, 0, 0, 0.3) url("../images/material/top_bg_banner.png")
        no-repeat top center;
    }
  }
  .capt {
    width: 940px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    @include transform(translateY(-50%));
    text-align: center;
    color: #fff;
    h6 {
      font-family: $f_nova;
      font-size: 20px;
      margin-bottom: 10px;
    }
    h1 {
      font-size: 60px;
      line-height: 72px;
    }
  }
}
.banner {
  margin-bottom: 100px;
  #map_contact {
    height: 600px;
  }
  &.map {
    margin-bottom: 90px;
  }
}
.text_inner {
  padding-bottom: 40px;
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 50px;
  .lf_txt {
    float: left;
    width: 440px;
    h3 {
      font-size: 26px;
      color: $c_333;
      line-height: 36px;
    }
    h4 {
      font-size: 20px;
      color: $c_333;
      margin-bottom: 20px;
      &.title {
        color: $c_adv;
        font-weight: 700;
      }
    }
    p {
      font-size: 18px;
      font-family: $f_lato;
      line-height: 30px;
      color: $c_666;
      margin-right: 50px;
    }
    .status {
      display: block;
      margin-top: 10px;
    }
  }
  .rh_txt {
    float: right;
    width: 780px;
    p {
      line-height: 30px;
      font-size: 18px;
      font-family: $f_lato;
      margin-bottom: 20px;
      color: $c_666;
    }
    h6 {
      font-size: 18px;
      color: $c_666;
      font-weight: 700;
      font-family: $f_lato;
      margin-bottom: 20px;
    }
    ul,
    ol {
      margin-bottom: 20px;
      li {
        font-size: 18px;
        line-height: 30px;
        font-family: $f_lato;
        color: $c_666;
        padding-left: 20px;
        position: relative;
        &:after {
          content: "";
          width: 8px;
          height: 8px;
          @include borderRadius(50%);
          background: $c_adv;
          position: absolute;
          left: 0;
          top: 12px;
        }
      }
    }
  }
  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
  &.btm_bnnr {
    padding-bottom: 100px;
    border-bottom: none;
    margin-bottom: 0;
  }
}

.sec_why {
  background: #f2f2f2;
  padding-bottom: 20px;
  .txt_top {
    padding: 95px 0 70px;
  }
}
.sec_service {
  padding: 145px 0 60px;
  .wrapper {
    width: 1260px;
    margin: 0 auto;
  }
  .blok_service {
    @extend .after_clear;
    position: relative;
    .img_lf {
      float: left;
      width: 720px;
    }
    .right_dec {
      float: right;
      width: 460px;
      padding: 0 50px 0 45px;
      @include boxSizing(border-box);
      .text {
        top: 50%;
        @include transform(translateY(-50%));
        position: absolute;
      }
      h6 {
        font-size: 14px;
        color: $c_adv;
        margin-bottom: 20px;
        font-family: $f_nova;
        text-transform: uppercase;
      }
      p {
        font-size: 16px;
        color: $c_999;
        font-family: $f_lato;
      }
    }
  }
  .txt_top {
    padding-bottom: 50px;
  }
  .slider_partner {
    width: 940px;
    margin: 0 auto;
    position: relative;
    .partner {
      a {
        &:hover {
          img {
            opacity: 0.6;
            @include transition(all linear 0.25s);
            @include transform(scale(0.95));
          }
        }
      }
      img {
        display: block;
        margin: 0 auto;
        @include transition(all linear 0.25s);
      }
    }
    .slick-arrow {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      font-size: 0;
      border: none;
      width: 17px;
      height: 28px;
      background: url("../images/material/arr_partner.png") no-repeat left
        center;
      cursor: pointer;
      @include transition(background-position linear 0.25s);
      &:hover {
        @include transition(background-position linear 0.25s);
        background-position: right center !important;
      }
      &.slick-prev {
        left: -80px;
      }
      &.slick-next {
        right: -80px;
        @include transform(rotate(180deg));
      }
    }
  }
}
.list_result {
  margin-bottom: 80px;
  .item {
    @extend .after_clear;
    position: relative;
    margin-bottom: 50px;
    .img {
      float: left;
      width: 400px;
    }
    .dec_result {
      width: 780px;
      position: absolute;
      float: right;
      right: 0;
      top: 50%;
      @include transform(translateY(-50%));
      a {
        display: block;
        &:hover {
          h2 {
            color: $c_adv;
            @include transition(color ease-out 0.25s);
          }
        }
      }
      h2 {
        font-size: 26px;
        color: $c_333;
        margin-bottom: 15px;
        @include transition(color ease-out 0.25s);
      }
      p {
        font-size: 18px;
        line-height: 26px;
        color: $c_999;
        font-family: $f_lato;
        margin-bottom: 0;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.pagging {
  display: block;
  text-align: center;
  margin-bottom: 60px;
  a {
    display: inline-block;
    font-size: 16px;
    font-family: $f_lato;
    color: $c_999;
    width: 25px;
    text-align: center;
    line-height: 20px;
    vertical-align: middle;
    @include transition(color ease-out 0.25s);
    &.prev,
    &.next {
      width: 58px;
      height: 10px;
      background: url("../images/material/arr_pagging.png") no-repeat top center;
      position: relative;
      &:after {
        width: 0px;
        height: 10px;
        background: url("../images/material/arr_pagging.png") no-repeat bottom
          center;
        content: "";
        position: absolute;
        right: 0;
        @include transition(width linear 0.2s);
      }
      &:hover {
        &:after {
          @include transition(width linear 0.2s);
          width: 58px;
        }
      }
    }
    &.prev {
      margin-right: 10px;
    }
    &.next {
      @include transform(rotate(180deg));
      margin-left: 10px;
    }
    &:hover,
    &.active {
      @include transition(color ease-out 0.25s);
      color: $c_333;
    }
  }
  &.career {
    a {
      font-weight: 700;
      color: $c_999;
      &:hover,
      &.active {
        color: $c_adv;
      }
      &.prev,
      &.next {
        background: none;
        @include transform(rotate(0deg));
        height: auto;
        width: auto;
        text-transform: uppercase;
        &:after {
          display: none;
        }
      }
      &.prev {
        margin-right: 25px;
      }
      &.next {
        margin-left: 25px;
      }
    }
  }
}

.career_wrap {
  border-top: 1px solid #d8d8d8;
  width: 1600px;
  margin: 0 auto;
}
.list_career {
  margin-bottom: 80px;
  .item {
    @extend .after_clear;
    padding: 0 40px 25px;
    border-bottom: 2px solid #d8d8d8;
    margin-bottom: 30px;
    @include transition(border-color linear 0.25s);
    .jobs {
      float: left;
      max-height: 80%;
      .status {
        display: block;
        font-size: 14px;
        font-family: $f_lato;
        font-weight: 700;
        color: $c_999;
        margin-bottom: 6px;
      }
      h3 {
        font-size: 24px;
        font-family: $f_lato;
        color: $c_333;
      }
    }
    .learn {
      font-size: 14px;
      font-weight: 700;
      color: $c_adv;
      text-transform: uppercase;
      display: inline-block;
      margin-top: 10px;
    }
    &:hover {
      @include transition(border-color linear 0.25s);
      border-color: $c_adv;
    }
  }
}
.form_box {
  form > p {
    color: #999;
    a {
      color: $c_adv;
      display: inline-block;
    }
  }
  .row {
    @extend .after_clear;
    margin-bottom: 30px;
    label {
      float: none;
      max-width: none;
      font-size: 17px;
      color: $c_666;
      display: block;
      margin-top: 18px;
      font-family: $f_lato;
      margin-bottom: 15px;
      font-weight: 600;
      &.mrgin {
        margin-top: 5px;
      }
    }
    p {
      font-size: 15px;
      color: $c_666;
      margin-bottom: 25px;
    }
    .field {
      display: block;
      @extend .after_clear;
      input[type="text"],
      input[type="email"],
      input[type="password"],
      input[type="number"],
      select,
      textarea {
        width: 100%;
        @include boxSizing(border-box);
        border: 1px solid #d8d8d8;
        font-family: $f_lato;
        font-size: 16px;
        color: $c_999;
        line-height: 20px;
        padding: 14px 20px;
        @include borderRadius(10px);
        background: transparent;
        @include transition(all ease-out 0.25s);
        &:focus {
          @include transition(all ease-out 0.25s);
          border-color: $c_adv;
          color: $c_adv;
        }
      }
      select {
        background: url("../images/material/arr_select_adv.png") no-repeat 95%
          center;
      }
      .select2-container {
        width: 100% !important;
        background: url("../images/material/arr_select_adv.png") no-repeat 95%
          center;
      }
      textarea {
        resize: none;
        height: 120px;
      }
      .g-recaptcha {
        margin: 30px 0;
      }
      input[type="submit"].btn_round {
        cursor: pointer;
        &.orn {
          background: $c_adv;
          color: #fff;
          &:hover {
            background: #962d08;
          }
        }
      }
      .pick {
        background: url("../images/material/ico_date.png") no-repeat 95% center !important;
      }
      .qty_input {
        width: 45px;
        display: inline-block;
        vertical-align: middle;
        .input_val {
          padding: 0;
          height: 50px;
          color: $c_333;
          border: none;
          font-size: 20px;
        }
        + span {
          display: inline-block;
          font-size: 17px;
          font-weight: 700;
          color: #333;
          vertical-align: middle;
          margin-left: 20px;
        }
      }
      .rating {
        display: inline-block;
        overflow: hidden;
        position: relative;
        input[type="radio"] {
          position: absolute;
          right: -10px;
          top: 10px;
          visibility: hidden;
          &:checked {
            ~ .ico_rat {
              background-position: bottom center;
            }
          }
        }
        .ico_rat {
          width: 23px;
          height: 21px;
          background: url("../images/material/rating_ico.png") no-repeat top
            center;
          display: block;
          float: right;
          cursor: pointer;
          margin: 0;
        }
      }
      .val {
        display: inline-block;
        vertical-align: bottom;
        margin-left: 10px;
        margin-bottom: 5px;
        font-size: 16px;
        font-family: $f_lato;
        font-weight: 700;
        color: $c_333;
      }
      &.full {
        margin-left: 0;
        .btn_round {
          &:before {
            border-color: $c_333;
          }
          span {
            color: $c_333;
          }
          &:after {
            background: $c_adv;
          }
          &:hover {
            span {
              color: #fff;
            }
          }
        }
        .pass_forgot {
          color: $c_adv;
          margin-top: 25px;
          font-size: 14px;
          font-weight: 600;
        }
      }
      &.gnder {
        .ck_radio {
          margin: 15px 20px 10px 0;
          display: inline-block;
        }
      }
      .col_ck {
        float: none;
        width: 100%;
        margin-right: 0;
        height: auto;
        display: block;
        @include boxSizing(border-box);
        position: relative;
        padding-left: 30px;
        margin-bottom: 15px;
        &:nth-of-type(3) {
          margin-right: 0;
        }
        &:last-of-type {
          margin-bottom: 30px;
        }
        input[type="radio"],
        input[type="checkbox"] {
          background: none;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          display: block;
          padding: 0;
          width: 100%;
          cursor: pointer;
          z-index: 5;
          &:checked {
            + .box_ck {
              background-position: bottom center;
            }
          }
        }
        .box_ck {
          width: 17px;
          height: 16px;
          background: url("../images/material/ico_radio.png") no-repeat top
            center;
          display: inline-block;
          position: absolute;
          left: 0;
          top: 3px;
          z-index: 2;
        }
        .txt_type {
          display: block;
          h6 {
            color: $c_adv;
            line-height: 16px;
            font-family: $f_lato;
            font-size: 15px;
            font-weight: 700;
          }
          p {
            margin-bottom: 0;
            font-family: $f_lato;
            font-size: 14px;
            color: $c_999;
            line-height: 18px;
            a {
              display: inline-block;
              color: $c_adv;
              position: relative;
              z-index: 5;
            }
          }
        }
        &.phone_numb {
          position: relative;
          input[type="checkbox"] {
            width: 20px;
            height: 20px;
            left: -2px;
            top: 0;
            margin: 0;
            &:checked {
              + .box_ck {
                + .txt_type .drop_numb {
                  max-height: 200px;
                  @nclude transition(all ease-out 0.3s);
                  opacity: 1;
                  z-index: 2;
                }
                + .txt_type .drop_numb .select_numb {
                  display: block;
                }
              }
            }
          }
          .txt_type {
            h6 {
              color: #666;
              font-size: 17px;
              margin-bottom: 10px;
            }
          }
          .drop_numb {
            height: auto;
            max-height: 0;
            @nclude transition(all ease-out 0.3s);
            opacity: 0;
            z-index: -1;
            label {
              font-size: 14px;
              font-weight: 400;
              color: #999;
            }
            .select_numb {
              position: relative;
              display: none;
              .selectcustom {
                position: absolute;
                width: 110px;
                border: none;
                @include borderRadius(0);
                background-size: 10px;
                background-position: 87% center;
                @include boxSizing(border-box);
                top: 0;
                bottom: 0;
                @include borderRadius(0 0 6px 4px);
                .btnselect {
                  padding-left: 50px;
                  font-size: 16px;
                  color: $c_adv;
                  line-height: 50px;
                  background: url("../images/material/arr_select_adv.png")
                    no-repeat 90% center;
                  background-size: 10px;
                  padding-right: 20px;
                  .img_ico {
                    position: absolute;
                    width: 25px;
                    height: 25px;
                    left: 12px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                  }
                }
                .dropselect {
                  position: absolute;
                  top: 100%;
                  left: 35px;
                  right: 10px;
                  padding: 0 10px 5px 15px;
                  background: #fff;
                  height: 100px;
                  overflow: auto;
                  @include boxShadow(0 0 10px 0px rgba(0, 0, 0, 0.15));
                  .optselect {
                    font-size: 14px;
                    padding: 8px 0;
                    cursor: pointer;
                  }
                }
              }
              > input {
                padding-left: 115px;
                &:focus {
                  border-color: #d8d8d8;
                }
              }
            }
          }
        }
      }
      .ck_interest {
        li {
          width: 45%;
          margin-right: 3%;
          display: inline-block;
          vertical-align: top;
          position: relative;
          &:nth-of-type(2n) {
            margin-right: 0;
          }
          input[type="checkbox"] {
            position: absolute;
            left: 0;
            right: auto;
            background: transparent;
            border: none;
            top: 0;
            bottom: 0;
            margin: auto;
            padding: 0;
            width: 80%;
            cursor: pointer;
            &:checked {
              + span {
                font-weight: 700;
                color: $c_adv;
              }
            }
          }
          span {
            display: inline-block;
            font-family: $f_lato;
            color: $c_999;
            font-size: 16px;
            line-height: 30px;
            @include transition(color ease-out 0.25s);
          }
          &:hover {
            span {
              @include transition(color ease-out 0.25s);
              color: $c_adv;
            }
          }
        }
      }
    }
    .col-2 {
      float: left;
      width: 45%;
      &:nth-child(2) {
        float: right;
        label {
          max-width: 110px;
        }
        .field {
          margin-left: 120px;
          select {
            padding-right: 30px;
            background-position: 86% center;
          }
        }
      }
    }
    &.sum {
      label {
        float: none;
        display: block;
        max-width: none;
        margin-bottom: 25px;
      }
      .field {
        &.full {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-flow: row wrap;
          .block {
            width: 22.5%;
            margin-right: 3.33%;
            h6 {
              font-weight: 500;
              color: #333;
              font-size: 14px;
              text-align: center;
              margin-bottom: 5px;
              width: 105px;
            }
            &:nth-child(4n) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  .select2-container--default .select2-selection--multiple {
    background: transparent;
    border-color: #d8d8d8;
    padding-right: 50px;
  }
  .select2-container--default
    .select2-selection--multiple
    .select2-selection__rendered {
    padding: 12px 10px;
  }
  .select2-container--default
    .select2-selection--multiple
    .select2-selection__choice__remove {
    color: #fff;
    margin-right: 5px;
  }
  .select2-container--default.select2-container--focus
    .select2-selection--multiple {
    border-color: #d8d8d8;
  }
  .select2-container--default
    .select2-selection--multiple
    .select2-selection__choice {
    background: $c_adv;
    font-size: 14px;
    color: #fff;
    padding: 5px;
    border: none;
  }
  hr {
    border: none;
    border-bottom: 1px solid #d8d8d8;
    display: block;
    margin: 40px 0;
  }
  &.apply {
    width: 630px;
  }
  &.form_contact {
    .row {
      label {
        font-size: 18px;
        margin-top: 16px;
      }
      input[type="text"],
      input[type="email"],
      input[type="number"],
      select,
      textarea {
        font-size: 18px;
      }
      textarea {
        height: 150px;
        font-style: italic;
      }
      .g-recaptcha {
        float: left;
        margin-top: 40px;
      }
      input[type="submit"].btn_round {
        margin-top: 50px;
        cursor: pointer;
        &.orn {
          background: $c_adv;
          color: #fff;
          &:hover {
            background: #962d08;
          }
        }
      }
    }
  }
  &.register {
    .row {
      .field {
        .btn_round {
          margin-top: 30px;
          margin-left: 60px;
        }
        &.btn_rw {
          p {
            font-size: 16px;
            color: $c_999;
            font-family: $f_lato;
            margin: 30px 0 0;
            line-height: 26px;
            a {
              color: $c_adv;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  &.login {
    .row {
      .field {
        &.full {
          margin-left: 0;
          margin-top: 40px;
          p {
            font-size: 16px;
            color: $c_999;
            font-family: $f_lato;
            &.left {
              margin-top: 20px;
            }
            a {
              color: $c_adv;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  .input_file {
    height: 50px;
    border: 1px solid #d8d8d8;
    width: 200px;
    position: relative;
    @include borderRadius(10px);
    background: url("../images/material/ico_file.png") no-repeat 90% center;
    input {
      width: 100%;
      @include boxSizing(border-box);
      border: none;
      height: 50px;
      z-index: 4;
    }
    span {
      font-size: 16px;
      color: #ccc;
      font-family: $f_lato;
      padding: 0 5px 0 20px;
      line-height: 20px;
      display: block;
      position: relative;
      top: 12px;
      width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @include boxSizing(border-box);
      z-index: 2;
    }
  }
}
.ck_radio {
  font-size: 16px;
  color: $c_666;
  font-family: $f_lato;
  padding-left: 26px;
  position: relative;
  line-height: 20px;
  margin-right: 45px;
  input[type="radio"],
  input[type="checkbox"] {
    background: url("../images/material/ico_radio.png") no-repeat top center;
    width: 17px;
    height: 16px;
    position: absolute;
    left: 0;
    top: 3px;
    padding: 0;
    cursor: pointer;
    z-index: 2;
    &:checked {
      background-position: bottom center !important;
    }
  }
  &:last-child {
    margin-right: 0;
  }
}

.thanks_pg {
  position: relative;
  .img {
    position: relative;
    overflow: hidden;
    img {
      display: block;
      width: 100%;
    }
    &:after {
      content: "";
      position: absolute;
      display: block;
      background: rgba(0, 0, 0, 0.6);
      left: 0;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;
      background-size: 100%;
    }
  }
  .text_thanks {
    width: 640px;
    top: 50%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    color: #fff;
    @include transform(translateY(-50%));
    h1 {
      font-size: 72px;
      margin-bottom: 20px;
    }
    p {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 30px;
      font-family: $f_lato;
    }
    .btn_round {
      &:hover {
        span {
          color: #fff;
        }
      }
    }
  }
}

.fllow {
  margin: 30px 0 50px;
  h2 {
    font-size: 18px;
    color: $c_666;
    font-family: $f_lato;
    font-weight: 700;
    margin-bottom: 15px;
  }
  a {
    display: inline-block;
    width: 43px;
    height: 43px;
    margin-right: 5px;
  }
}

.nav_destination {
  text-align: center;
  display: block;
  margin: 35px 0 0;
  border-bottom: 1px solid #d8d8d8;
  .list {
    a {
      display: inline-block;
      font-size: 13px;
      color: $c_999;
      padding: 10px 20px 30px;
      font-size: 16px;
      margin-right: 15px;
      position: relative;
      @include transition(color ease-out 0.25s);
      &.active,
      &:hover {
        color: $c_333;
        @include transition(color ease-out 0.25s);
        &:after {
          width: 100%;
          @include transition(all linear 0.25s);
        }
      }
      &:last-of-type {
        margin-right: 0;
      }
      &:after {
        content: "";
        height: 4px;
        background: $c_adv;
        left: 0;
        right: 0;
        width: 0;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 0px;
        @include transition(all linear 0.25s);
      }
    }
  }
}

.detail_destination {
  padding: 55px 0 40px;
}
.detail_sec {
  margin-bottom: 50px;
  > h3 {
    font-size: 26px;
    color: $c_333;
    line-height: 36px;
    text-align: center;
    margin-bottom: 20px;
  }
  .text_article {
    width: 780px;
    margin: 0 auto;
    text-align: center;
    .isi {
      max-height: 140px;
      @include transition(all ease-out 0.8s);
      position: relative;
      overflow: hidden;
    }
    p {
      font-size: 18px;
      color: $c_666;
      font-family: $f_lato;
      line-height: 30px;
    }
    .rd_more {
      font-size: 14px;
      font-weight: 700;
      color: $c_adv;
      text-transform: uppercase;
      display: inline-block;
      letter-spacing: 2px;
      padding-bottom: 6px;
      position: relative;
      margin-top: 15px;
      &:after {
        content: "";
        height: 2px;
        background: $c_adv;
        left: 0;
        right: 0;
        bottom: 0;
        width: 0;
        @include transition(width ease-out 0.25s);
        display: block;
        margin: auto;
        position: absolute;
      }
      &:hover {
        &:after {
          @include transition(width ease-out 0.25s);
          width: 100%;
        }
      }
    }
    &.show {
      .isi {
        max-height: 5000px;
        @include transition(all ease-out 1s);
      }
    }
  }
  &.text_tmt {
    height: 335px;
    background: url("../images/material/bg_text_tmt.jpg") no-repeat;
    position: relative;
    margin-top: 100px;
    .dec_txt {
      padding-left: 55px;
      width: 400px;
      padding-top: 42px;
      h2 {
        font-size: 30px;
        line-height: 36px;
        color: $c_333;
        margin-bottom: 20px;
      }
      p {
        font-size: 18px;
        font-family: $f_lato;
        color: $c_666;
        line-height: 26px;
      }
      .btn_round {
        margin-top: 15px;
        line-height: 46px;
        min-width: 180px;
      }
    }
  }
}
.list_page {
  .recomend_list {
    margin-bottom: 50px;
    .item {
      margin-bottom: 40px;
    }
  }
}
.list_desti {
  margin-bottom: 30px;
  .slide {
    float: left;
    width: 24.065%;
    margin-right: 1.246%;
    margin-bottom: 50px;
    min-height: 500px;
    &:nth-of-type(4n) {
      margin-right: 0;
    }
  }
}
.item_detail {
  margin: 30px 0;
  .box {
    width: 18.75%;
    margin-right: 1.5625%;
    position: relative;
    a {
      display: block;
    }
    &:nth-of-type(5n) {
      margin-right: 0;
    }
    .img {
      position: relative;
      overflow: hidden;
      img {
        display: block;
        @include transition(transform 5s ease-out);
      }
      &:after {
        content: "";
        background: url("../images/material/bg_img_list.png") no-repeat bottom
          center;
        background-size: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        display: block;
        opacity: 0.6;
      }
    }
    .name {
      position: absolute;
      bottom: 25px;
      left: 25px;
      right: 25px;
      margin: auto;
      display: block;
      text-align: center;
      h5 {
        font-weight: 700;
        font-size: 14px;
        color: #fff;
        margin-bottom: 10px;
      }
      .place {
        font-size: 11px;
        font-weight: 100;
        color: #fff;
        display: block;
      }
    }
    .round_no {
      width: 32px;
      height: 32px;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      border: 2px solid #fff;
      @include borderRadius(50%);
      top: 23px;
      z-index: 3;
      color: #fff;
      line-height: 32px;
      text-align: center;
      font-size: 14px;
      font-weight: 700;
    }
    &:hover {
      .img {
        img {
          @include transform(scale(1.3));
          @include transition(transform 10s ease-out);
        }
      }
    }
  }
}
#map_location {
  height: 400px;
}
.map_info {
  @extend .after_clear;
  margin-top: 30px;
  .map_content {
    float: left;
    width: 710px;
  }
  .info {
    float: right;
    width: 460px;
    border: 1px solid #d8d8d8;
    @include borderRadius(3px);
    .label_info {
      background: #fafafa;
      padding: 20px 40px;
      h3 {
        font-size: 14px;
        font-family: $f_nova;
        color: $c_adv;
        padding-left: 35px;
        line-height: 20px;
        letter-spacing: 3px;
        background: url("../images/material/ico_info.png") no-repeat left center;
      }
    }
    .ket_map {
      padding: 20px 40px;
      p {
        @extend .after_clear;
        font-size: 16px;
        font-family: $f_lato;
        color: $c_666;
        margin-bottom: 25px;
        label {
          float: left;
          display: inline-block;
          max-width: 150px;
        }
        span {
          display: block;
          margin-left: 160px;
          color: $c_333;
        }
      }
    }
  }
}

.sec_recomend {
  .wrapper {
    > h3 {
      font-size: 26px;
      line-height: 36px;
      color: $c_333;
      margin-bottom: 30px;
    }
  }
}
.recomend_list {
  margin-bottom: 100px;
  .item {
    @include boxSizing(border-box);
    @include borderRadius(5px);
    width: 23.75%;
    margin-right: 1.6667%;
    min-height: 420px;
    &:nth-of-type(4n) {
      margin-right: 0;
    }
    .img {
      position: relative;
      overflow: hidden;
      img {
        display: block;
      }
      .hov_see {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.6);
        opacity: 0;
        @include transform(scale(1.15));
        display: block;
        @include transition(all ease-out 0.25s);
        .btn_round {
          position: absolute;
          left: 50%;
          width: 175px;
          top: 50%;
          @include transform(translate(-50%, -50%));
          display: block;
          &:hover {
            span {
              color: $c_adv;
            }
          }
        }
      }
    }
    .dec {
      padding: 20px 25px;
      border: 1px solid #d8d8d8;
      h3 {
        font-size: 16px;
        text-transform: uppercase;
        color: $c_333;
        font-weight: 700;
        letter-spacing: 2px;
        margin-bottom: 15px;
        line-height: 28px;
      }
      p {
        margin-bottom: 5px;
        font-size: 16px;
        color: $c_333;
        font-family: $f_lato;
        padding-left: 25px;
        line-height: 25px;
        &.loc {
          background: url("../images/material/ico_loc.png") no-repeat left
            center;
          font-size: 14px;
        }
        &.culture {
          background: url("../images/material/ico_culture.png") no-repeat left
            center;
          font-size: 14px;
        }
        &.dt_price {
          background: url("../images/material/ico_dt_price.png") no-repeat left
            center;
          font-size: 14px;
        }
        &.dt_trip {
          background: url("../images/material/ico_dt_trip.png") no-repeat left
            center;
          font-size: 14px;
        }
      }
      .price_bx {
        padding: 15px 25px;
        margin: 10px -25px -20px;
        background: #333;
        color: #fff;
        position: relative;
        .abs {
          position: absolute;
          top: 15px;
          right: 25px;
          text-transform: uppercase;
        }
        label {
          font-size: 13px;
          display: block;
          margin-bottom: 10px;
          color: #999;
        }
        h6 {
          font-size: 18px;
          font-weight: 600;
        }
        &.trans {
          background: #fff;
          border-top: 1px solid #d8d8d8;
          margin: 10px 0 0;
          padding: 15px 0 0;
          p {
            padding-left: 0;
            margin: 0;
            font-size: 14px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            color: #aaa;
            height: 50px;
          }
        }
      }
    }
    &:hover {
      .img {
        .hov_see {
          opacity: 1;
          @include transform(scale(1));
          @include transition(all ease-out 0.35s);
        }
      }
    }
  }
}
.package_sec {
  @extend .after_clear;
  margin-bottom: 100px;
  .left_package {
    float: left;
    width: 1000px;
    padding: 0 20px 0 0px;
    @include boxSizing(border-box);
    > h3 {
      font-size: 26px;
      color: $c_333;
      margin-bottom: 20px;
    }
    p {
      font-size: 18px;
      line-height: 30px;
      color: $c_666;
    }
    &.checkout_left {
      padding: 0;
    }
    .box_checkout {
      .boxs {
        background: #fafafa;
        padding: 50px 80px 40px;
        @include borderRadius(5px);
        margin-bottom: 50px;
        h3 {
          font-size: 24px;
          color: $c_333;
          padding-left: 40px;
          background: url("../images/material/ico_login.png") no-repeat left
            center;
          background-size: 20px;
          line-height: 30px;
          margin-bottom: 35px;
        }
        &.white {
          background: #fff;
          padding: 0 80px;
          text-align: center;
          .ck_radio {
            text-align: left;
            display: block;
            a {
              color: $c_adv;
            }
          }
          .btn_round {
            margin-top: 30px;
          }
        }
      }
    }
  }
  .right_package {
    float: right;
    width: 460px;
    .box {
      border: 1px solid #d8d8d8;
      @include borderRadius(3px);
      margin-bottom: 22px;
      .label_info {
        padding: 20px 40px;
        background: #fafafa;
        h3 {
          font-size: 14px;
          font-family: $f_nova, cursive;
          color: $c_adv;
          padding-left: 35px;
          line-height: 20px;
          letter-spacing: 3px;
          background: url(../images/material/ico_info.png) no-repeat left center;
        }
      }
      .isi_info {
        padding: 30px 40px;
        .img {
          margin-bottom: 30px;
          img {
            width: 100%;
          }
        }
        .title_box {
          margin-bottom: 30px;
          h3 {
            font-weight: 600;
            font-size: 24px;
            margin-bottom: 6px;
          }
          h6 {
            font-size: 16px;
          }
        }
        .col {
          @extend .after_clear;
          margin-bottom: 25px;
          label {
            float: left;
            max-width: 100px;
            display: block;
            font-size: 16px;
            font-family: $f_lato;
            color: $c_666;
            line-height: 20px;
          }
          p {
            display: block;
            margin-left: 100px;
            margin-bottom: 0;
            font-size: 16px;
            font-family: $f_lato;
            color: $c_333;
          }
          a {
            color: $c_333;
            font-size: 16px;
            font-family: $f_lato;
            line-height: 20px;
            display: inline-block;
            margin-bottom: 5px;
            &.email {
              padding-left: 35px;
              background: url("../images/material/ico_mail.png") no-repeat left
                center;
            }
            &.download {
              padding-left: 35px;
              background: url("../images/material/ico_pdf.png") no-repeat left
                center;
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
          &.share {
            label {
              vertical-align: middle;
              display: inline-block;
              font-size: 16px;
              font-family: $f_lato;
              margin-right: 20px;
              color: $c_666;
              float: none;
            }
            a {
              width: 30px;
              height: 30px;
              font-size: 0;
              vertical-align: middle;
              margin-right: 5px;
              margin-bottom: 0;
              display: inline-block;
            }
          }
        }
        > p {
          font-size: 16px;
          font-weight: 700;
          color: #666;
          margin-bottom: 20px;
        }
        ul {
          li {
            font-size: 16px;
            color: #666;
            font-family: "Lato", sans-serif;
            line-height: 20px;
            padding-left: 30px;
            background: url("../images/material/arr_side.png") no-repeat left
              4px;
            margin-bottom: 10px;
          }
        }
      }
      &.grey_bx {
        position: relative;
        overflow: hidden;
        .txt {
          background: #333;
          padding: 30px 40px 40px;
          p {
            color: #fff;
            font-size: 16px;
            font-family: "Lato", sans-serif;
            margin-bottom: 25px;
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
        .isi_info {
          text-align: center;
          h5 {
            font-size: 16px;
            color: #333;
            text-transform: uppercase;
            font-weight: 500;
            a {
              color: #333;
              font-weight: 700;
              font-size: 20px;
              display: inline-block;
              margin-left: 5px;
            }
          }
          > label {
            display: block;
            margin: 15px 0;
            font-size: 15px;
            text-transform: uppercase;
            font-weight: 600;
          }
          .btn_round {
            display: block;
            @include borderRadius(10px);
            &:after,
            &:before {
              @include borderRadius(10px);
            }
          }
        }
      }
      &.book_now {
        .isi_info {
          padding: 30px 20px 30px 40px;
          .booknow_info {
            .row {
              @extend .after_clear;
              margin-bottom: 25px;
              .colmn {
                float: left;
                margin-right: 15px;
                .qty_input {
                  position: relative;
                  padding: 0 30px;
                  .input_val {
                    border: none;
                    width: 50px;
                    padding: 0;
                    text-align: center;
                    font-size: 20px;
                    color: $c_333;
                    height: 30px;
                    line-height: 30px;
                  }
                  button {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    border: none;
                    font-size: 0;
                    z-index: 2;
                    cursor: pointer;
                    &.min {
                      left: 0;
                      right: auto;
                      width: 30px;
                      height: 30px;
                      background: url("../images/material/ico_min.png")
                        no-repeat top center;
                    }
                    &.plus {
                      right: 0;
                      left: auto;
                      width: 30px;
                      height: 30px;
                      background: url("../images/material/ico_plus.png")
                        no-repeat top center;
                    }
                    &:hover {
                      background-position: bottom center !important;
                    }
                  }
                }
                > h6 {
                  text-align: right;
                  font-size: 16px;
                  font-family: $f_lato;
                  color: $c_666;
                  margin-top: 8px;
                }
                > span {
                  font-size: 16px;
                  font-family: $f_lato;
                  color: $c_666;
                  line-height: 20px;
                }
                &:nth-of-type(1) {
                  width: 100px;
                }
                &:nth-of-type(2) {
                  width: 110px;
                  margin-top: 3px;
                  span {
                    text-align: center;
                    display: block;
                    color: $c_333;
                    font-weight: 600;
                    font-size: 14px;
                  }
                }
                &:nth-of-type(3) {
                  margin-right: 0;
                  text-align: right;
                  width: 155px;
                }
              }
              > label {
                font-size: 16px;
                display: block;
                margin-bottom: 10px;
                color: $c_666;
                font-family: "Lato", sans-serif;
              }
              select {
                padding: 12px 20px;
                font-size: 16px;
                background: url("../images/material/arr_select_adv.png")
                  no-repeat 95% center;
                display: block;
                width: 100%;
                @include boxSizing(border-box);
                @include borderRadius(6px);
              }
              &.links {
                @extend .after_clear;
                margin-top: 30px;
                a {
                  color: $c_adv;
                  display: inline-block;
                  font-size: 14px;
                  text-decoration: underline;
                  float: left;
                  @include transition(all ease-out 0.25s);
                  &:nth-child(2) {
                    float: right;
                  }
                  &:hover {
                    font-weight: 500;
                    @include transition(all ease-out 0.25s);
                  }
                }
              }
            }
          }
        }
        .total {
          background: $c_333;
          margin: 0 -20px -30px -40px;
          padding: 20px 25px 30px 40px;
          label {
            color: #fff;
            font-size: 20px;
          }
          p {
            color: #fff;
            text-align: right;
            font-size: 20px;
          }
        }
      }
    }
    .ket_btm {
      a.left {
        color: $c_adv;
        text-decoration: underline;
        font-size: 18px;
        font-family: $f_lato;
        margin: 20px 0 0 35px;
        display: inline-block;
      }
      .btn_round {
        margin-right: 30px;
      }
    }
  }
}
.qty_input {
  position: relative;
  padding: 0 30px;
  width: 45px;
  .input_val {
    border: none;
    width: 50px;
    padding: 0;
    text-align: center;
    font-size: 20px;
    color: $c_333;
    height: 30px;
    line-height: 30px;
  }
  button {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    border: none;
    font-size: 0;
    z-index: 2;
    cursor: pointer;
    &.min {
      left: 0;
      right: auto;
      width: 30px;
      height: 30px;
      background: url("../images/material/ico_min.png") no-repeat top center;
    }
    &.plus {
      right: 0;
      left: auto;
      width: 30px;
      height: 30px;
      background: url("../images/material/ico_plus.png") no-repeat top center;
    }
    &:hover {
      background-position: bottom center !important;
    }
  }
}

.tab_packages {
  margin: 0 -20px 0 -50px;
  .nav {
    padding: 0 20px 0 50px;
    border-bottom: 1px solid #d8d8d8;
    margin: 55px 0;
    display: block;
    position: relative;
    a {
      font-size: 17px;
      display: inline-block;
      color: $c_999;
      padding-bottom: 20px;
      margin-right: 50px;
      @include transition(color ease-out 0.25s);
      position: relative;
      &.active,
      &:hover {
        color: $c_333;
        @include transition(color ease-out 0.25s);
        &:after {
          width: 100%;
          @include transition(all linear 0.25s);
        }
      }
      &:last-of-type {
        margin-right: 0;
      }
      &:after {
        content: "";
        height: 3px;
        background: $c_adv;
        left: 0;
        right: 0;
        width: 0;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 0px;
        @include transition(all linear 0.25s);
      }
    }
  }
  .tab_content {
    padding: 0 20px 0 50px;
    .dec_tab {
      display: none;
      position: relative;
      .drop_mobile {
        display: none;
      }
      .list_diagram {
        position: relative;
        .info_tour {
          padding-left: 95px;
          position: relative;
          margin-bottom: 30px;
          .day {
            position: absolute;
            left: 0;
            display: inline-block;
            padding-left: 18px;
            font-size: 18px;
            font-weight: 700;
            color: $c_adv;
            &:before {
              content: "";
              width: 10px;
              height: 10px;
              background: $c_adv;
              position: absolute;
              left: -5px;
              top: 5px;
              display: block;
              @include borderRadius(50%);
            }
          }
          h6 {
            font-size: 18px;
            color: $c_666;
            font-weight: 700;
            margin-bottom: 10px;
          }
          p {
            font-size: 16px;
            line-height: 26px;
          }
          .img {
            margin: 20px 0;
          }
          .box_slide {
            padding: 20px;
            background: #fafafa;
            h4 {
              font-size: 13px;
              color: $c_adv;
              font-weight: 500;
              margin-bottom: 15px;
            }
            .slider {
              .slide {
                position: relative;
                margin: 0 5px;
                cursor: pointer;
                .images {
                  position: relative;
                  img {
                    display: block;
                    width: 100%;
                  }
                  .star {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    padding: 3px 5px;
                    background: rgba(0, 0, 0, 0.7);
                    span {
                      width: 9px;
                      height: 8px;
                      background: url("../images/material/star_litle.png")
                        no-repeat top center;
                      display: inline-block;
                      &.act {
                        background-position: bottom center;
                      }
                    }
                  }
                }
                .bx_dec {
                  padding: 10px;
                  border: 1px solid #ddd;
                  h6 {
                    font-size: 13px;
                    margin-bottom: 0;
                  }
                }
                &:hover {
                  .bx_dec {
                    h6 {
                      color: $c_adv;
                    }
                  }
                }
              }
              .slick-arrow {
                font-size: 0;
                border: none;
                width: 10px;
                height: 10px;
                padding: 0;
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
                border-right: 10px solid #333;
                background: none;
                position: absolute;
                z-index: 2;
                left: -12px;
                cursor: pointer;
                margin: auto;
                top: 0;
                bottom: 0;
                &.slick-next {
                  left: auto;
                  right: -12px;
                  @include transform(rotate(180deg));
                }
                &.slick-prev {
                }
              }
              &:after {
                display: block;
                content: "";
                clear: both;
              }
            }
          }
        }
        &:after {
          content: "";
          display: block;
          width: 1px;
          background: $c_adv;
          position: absolute;
          left: 4px;
          bottom: 0;
          top: 5px;
          height: 100%;
        }
        &:before {
          content: "";
          position: absolute;
          width: 10px;
          height: 5px;
          background: #fff;
          left: -4px;
          top: 0;
        }
      }
      ul {
        li {
          font-size: 18px;
          line-height: 30px;
          position: relative;
          padding-left: 25px;
          margin-bottom: 13px;
          &:before {
            content: "";
            width: 10px;
            height: 10px;
            background: $c_adv;
            position: absolute;
            left: 0;
            top: 10px;
            @include borderRadius(10px);
          }
        }
      }
      table {
        width: 100%;
        border: 1px solid #d8d8d8;
        @include borderRadius(4px);
        margin-bottom: 20px;
        position: relative;
        thead {
          background: #fafafa;
          @include borderRadius(4px);
          th {
            color: $c_adv;
            text-transform: uppercase;
            font-family: $f_nova;
            font-size: 14px;
            height: 45px;
            padding: 5px 10px;
            vertical-align: middle;
            text-align: left;
            &:nth-of-type(1) {
              @include borderRadius(0 0 4px 0);
              padding-left: 40px;
            }
            &:last-of-type {
              width: 150px;
              padding-right: 40px;
            }
          }
        }
        tbody {
          td {
            padding: 20px 10px;
            font-family: $f_lato;
            font-size: 14px;
            color: $c_666;
            border-bottom: 1px solid #d8d8d8;
            &:nth-of-type(1) {
              padding-left: 40px;
            }
            &:last-of-type {
              padding-right: 40px;
            }
          }
        }
      }
      .drop_txt {
        max-height: 430px;
        padding-bottom: 50px;
        position: relative;
        overflow-y: hidden;
        padding-left: 5px;
        @include transition(max-height ease-out 0.6s);
        .drop_btn {
          position: absolute;
          left: 0px;
          right: 0;
          bottom: 0;
          margin: auto;
          top: auto;
          z-index: 3;
          background: #fff;
          height: 60px;
          span {
            width: 30px;
            height: 30px;
            background: #fff url("../images/material/ico_plus.png") no-repeat
              top center;
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0px;
            margin: auto;
            z-index: 2;
            cursor: pointer;
            &:hover {
              background-position: bottom center !important;
            }
            &.active {
              background: #fff url("../images/material/ico_min.png") no-repeat
                top center;
            }
          }
          &:before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 15px;
            margin: auto;
            display: block;
            height: 1px;
            background: #ccc;
            z-index: 1;
          }
        }
        &.active {
          max-height: unset;
          @include transition(max-height ease-out 0.6s);
        }
      }
    }
  }
}

.status_pop {
  > .row {
    &:nth-of-type(1) {
      height: 60px;
      background: #fafafa;
      font-family: $f_nova;
      color: $c_adv;
      border-bottom: none;
      .col {
        text-transform: uppercase;
      }
    }
  }
  .row {
    @extend .after_clear;
    font-size: 14px;
    font-family: $f_lato;
    border-bottom: 1px solid #d8d8d8;
    @include transition(background ease-out 0.25s);
    background: #fff;
    .col {
      float: left;
      width: 65%;
      margin-right: 5%;
      padding: 20px 30px;
      @include boxSizing(border-box);
      &:nth-of-type(2) {
        width: 25%;
        margin-right: 0;
      }
      .ico_status {
        display: block;
        position: relative;
        padding-left: 30px;
        line-height: 20px;
        font-family: $f_lato;
        color: $c_333;
        font-weight: 900;
        font-size: 14px;
        text-transform: capitalize;
        &:before {
          height: 18px;
          width: 18px;
          background: #4bba52;
          @include borderRadius(50%);
          display: inline-block;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          content: "";
        }
        &.sold {
          &:before {
            background: #ed1c24;
          }
        }
        &.wait {
          &:before {
            background: #fbaf5d;
          }
        }
      }
    }
    a {
      color: $c_666;
      display: block;
      @extend .after_clear;
    }
    &:hover {
      > a {
        background: #d8d8d8;
        @include transition(background ease-out 0.25s);
        background: #d8d8d8;
      }
    }
    &.select_btn {
      background: $c_333;
      border-bottom: none;
      .col {
        width: 25%;
        color: #fff;
        &:nth-of-type(1) {
          padding-right: 10px;
          font-size: 20px;
          margin-top: 10px;
          font-weight: 700;
        }
        &:nth-of-type(2) {
          width: 70%;
          text-align: right;
        }
        a {
          &.btn_round {
            display: inline-block;
            min-width: 150px;
            line-height: 40px;
            margin-left: 20px;
            span {
              color: #fff;
            }
          }
        }
      }
      &:hover {
        a {
          background: none;
        }
      }
    }
  }
  .isi {
    max-height: 300px;
    overflow: auto;
    position: relative;
  }
}
.signin_pg {
  position: relative;
  .img {
    img {
      width: 100%;
    }
  }
}
.form_sign {
  h6 {
    font-size: 14px;
    color: $c_adv;
    font-family: $f_nova;
    margin-bottom: 15px;
    letter-spacing: 2px;
  }
  h1 {
    font-size: 50px;
    color: $c_333;
    margin-bottom: 45px;
  }
  input[type="email"],
  input[type="password"] {
    width: 100%;
    border: 1px solid $c_999;
    color: $c_adv;
    @include boxSizing(border-box);
    background: transparent;
    font-size: 14px;
    font-family: $f_lato;
    padding: 20px 30px;
    @include borderRadius(6px);
    margin-bottom: 20px;
    font-weight: 300;
  }

  position: absolute;
  left: 0;
  right: 0;
  width: 470px;
  top: 50%;
  @include transform(translateY(-50%));
  margin: auto;
  .link_btn {
    margin-top: 40px;
    @extend .after_clear;
    text-align: center;
    > span {
      display: inline-block;
      font-size: 18px;
      color: $c_333;
      line-height: 60px;
    }
    .right {
      span {
        color: $c_333;
      }
    }
  }
  .link_ck {
    @extend .after_clear;
    .link_forgot {
      float: right;
      font-family: $f_lato;
      color: $c_999;
      font-size: 16px;
      @include transition(color ease-out 0.25s);
      &:hover {
        @include transition(color ease-out 0.25s);
        color: $c_adv;
      }
    }
  }
  .ck_radio {
    color: $c_999;
    input[type="checkbox"] {
      background: url("../images/material/ico_radio_2.png") no-repeat top center;
    }
  }
}

.box_signup {
  background: #f9f9f9;
  @extend .after_clear;
  margin-bottom: 60px;
  position: relative;
  .img {
    width: 550px;
    position: absolute;
    left: auto;
    bottom: 0;
    top: 130px;
    right: 50px;
    img {
      position: relative;
      width: 100%;
    }
  }
  .right_form {
    width: 900px;
    float: left;
    @include boxSizing(border-box);
    padding: 125px 0px 50px 50px;
    &.log_desti {
      padding-top: 60px;
    }
  }
}

.list_step {
  @extend .after_clear;
  width: 960px;
  margin: -50px auto 55px;
  .step {
    float: left;
    width: 33.33%;
    @include boxSizing(border-box);
    padding: 12px 30px 8px;
    border: 1px solid #d8d8d8;
    border-right: none;
    &:nth-of-type(1) {
      padding-left: 40px;
      @include borderRadius(30px 0 0 30px);
    }
    &:nth-of-type(3) {
      border-right: 1px solid #d8d8d8;
      padding-right: 40px;
      @include borderRadius(0 30px 30px 0);
    }
    h5 {
      font-size: 18px;
      color: $c_adv;
      margin-bottom: 5px;
    }
    span {
      font-size: 14px;
      color: $c_999;
      font-family: $f_lato;
      display: block;
    }
    &.active {
      background: $c_adv;
      border-color: $c_adv;
      h5 {
        color: #fff;
      }
      span {
        color: #fff;
      }
    }
  }
}
.top_form {
  @extend .after_clear;
  margin-bottom: 35px;
  p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  h2 {
    float: left;
    font-size: 24px;
    color: $c_333;
    padding-left: 50px;
    background: url("../images/material/ico_login.png") no-repeat left center;
    line-height: 36px;
    float: none;
    + p {
      margin-top: 30px;
    }
  }
  .right {
    color: $c_999;
    margin-top: 10px;
    margin-bottom: 0;
    font-family: $f_lato;
    color: $c_999;
    float: none;
    margin-top: 20px;
    display: block;
    a {
      color: $c_adv;
      text-decoration: underline;
      display: inline-block;
      margin-left: 5px;
    }
  }
  &.log2 {
    h2 {
      background: url("../images/material/ico_plan.png") no-repeat left center;
    }
  }
}

.bnnr_dash {
  position: relative;
  .info_dash {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    @include transform(translateY(-50%));
    .ico_profile {
      width: 140px;
      height: 140px;
      @include borderRadius(50%);
      overflow: hidden;
      display: inline-block;
      vertical-align: middle;
    }
    .info_profile {
      display: inline-block;
      vertical-align: middle;
      margin-left: 90px;
      h4 {
        color: #fff;
        font-size: 30px;
        font-weight: 300;
        margin-bottom: 15px;
        span {
          display: block;
          font-weight: 600;
          margin-top: 5px;
        }
      }
      p {
        color: $c_999;
        font-size: 16px;
      }
    }
  }
}

.pg_dashboard {
  padding: 65px 0;
  .block_lf {
    float: left;
    width: 460px;
  }
  .block_rh {
    float: right;
    width: 780px;
    .list_trip {
      .box_trip {
        position: relative;
        margin-bottom: 22px;
        .img {
          position: relative;
          img {
            display: block;
          }
          &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            display: block;
            background: rgba(0, 0, 0, 0.4);
          }
        }
        .text_trip {
          position: absolute;
          top: 50%;
          @include transform(translateY(-50%));
          left: 65px;
          right: 65px;
          color: #fff;
          h6 {
            font-family: $f_nova;
            font-size: 14px;
            letter-spacing: 2px;
            margin-bottom: 22px;
          }
          h3 {
            font-weight: 700;
            font-size: 30px;
            margin-bottom: 15px;
          }
          p {
            font-size: 20px;
            margin-bottom: 25px;
          }
          .btn_round {
            &:before {
              border-width: 1px;
            }
          }
        }
      }
    }
    .box {
      .label_info {
        a {
          position: absolute;
          right: 40px;
          top: 25px;
          margin: auto;
          display: inline-block;
          color: $c_adv;
          font-size: 14px;
        }
      }
      .isi_info {
        .col {
          p {
            margin-left: 200px;
            &.mail {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              cursor: help;
            }
          }
        }
      }
    }
    .select2-container--default
      .select2-selection--multiple
      .select2-selection__rendered {
      padding: 5px 10px;
    }
    .select2-container--default
      .select2-selection--multiple
      .select2-selection__choice__remove {
      color: #fff;
      margin-right: 5px;
    }
    .select2-container--default.select2-container--focus
      .select2-selection--multiple {
      border-color: #d8d8d8;
    }
    .select2-container--default
      .select2-selection--multiple
      .select2-selection__choice {
      background: $c_adv;
      font-size: 14px;
      color: #fff;
      padding: 5px;
      border: none;
    }
  }
  .box {
    border: 1px solid #d8d8d8;
    @include borderRadius(3px);
    margin-bottom: 22px;
    .label_info {
      padding: 15px 40px;
      background: #fafafa;
      h3 {
        font-size: 14px;
        font-family: $f_nova, cursive;
        color: $c_adv;
        padding-left: 35px;
        line-height: 30px;
        letter-spacing: 3px;
      }
      &.profile {
        h3 {
          background: url("../images/material/ico_login_2.png") no-repeat left
            center;
        }
      }
      &.history {
        h3 {
          background: url("../images/material/ico_history.png") no-repeat left
            center;
        }
      }
      &.photo {
        h3 {
          background: url("../images/material/ico_photo.png") no-repeat left
            center;
        }
      }
    }
    .isi_info {
      padding: 30px 40px;
      .col {
        @extend .after_clear;
        margin-bottom: 25px;
        label {
          float: left;
          max-width: 140px;
          display: block;
          font-size: 16px;
          font-family: $f_lato;
          color: $c_999;
          line-height: 20px;
        }
        p {
          display: block;
          margin-left: 150px;
          margin-bottom: 0;
          font-size: 16px;
          font-family: $f_lato;
          color: $c_333;
          font-weight: 600;
          &.mail {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: help;
          }
        }
        &.link_edit {
          margin: 35px -40px -30px;
          text-align: center;
          border-top: 1px solid #d8d8d8;
          .edit {
            font-size: 14px;
            color: $c_adv;
            display: inline-block;
            padding: 12px;
            font-weight: 500;
          }
        }
      }
      table {
        width: 100%;
        tbody {
          td {
            padding: 15px 5px;
            font-size: 16px;
            &:first-child {
              padding-left: 0;
              color: $c_999;
            }
            &:last-child {
              text-align: right;
              padding-right: 0;
              a {
                color: $c_adv;
                font-size: 16px;
                font-weight: 500;
              }
            }
          }
          tr {
            &:nth-child(1) {
              td {
                padding-top: 0;
              }
            }
          }
        }
      }
      .input-list {
        display: none;
      }
      .list-box {
        width: 350px;
        margin: 0 auto;
        .toggle {
          position: relative;
          text-align: center;
          h3 {
            padding-top: 55px;
            font-size: 18px;
            font-family: $f_lato;
            color: #fff;
            text-align: center;
            left: 0;
            right: 0;
            background: url("../images/material/upload_ico.png") no-repeat top
              center;
            position: absolute;
            top: 50%;
            @include transform(translateY(-50%));
            font-weight: 600;
          }
          .img {
            position: relative;
            height: 350px;
            width: 350px;
            overflow: hidden;
          }
          a {
            font-size: 18px;
            display: inline-block;
            padding-left: 30px;
            background: url("../images/material/del.png") no-repeat left center;
            color: $c_adv;
            font-weight: 500;
            margin-top: 30px;
            line-height: 25px;
          }
        }
        &.sucess {
          .toggle {
            display: none;
          }
        }
        .box {
          border: none;
          text-align: center;
          margin-bottom: 0;
          overflow: hidden;
          .img {
            position: relative;
            height: 350px;
            width: 350px;
            overflow: hidden;
            margin: 0 auto;
            display: block;
          }
          a {
            font-size: 18px;
            display: inline-block;
            padding-left: 30px;
            background: url("../images/material/del.png") no-repeat left center;
            color: $c_adv;
            font-weight: 500;
            margin-top: 30px;
            line-height: 25px;
          }
        }
      }
    }
  }
  .box_add {
    padding: 35px;
    border: 1px solid #d8d8d8;
    @include borderRadius(5px);
    text-align: center;
    a {
      display: block;
      color: $c_999;
      &:hover {
        color: $c_adv;
        .ico {
          background-position: bottom center !important;
        }
      }
    }
    h4 {
      font-size: 30px;
      font-weight: 300;
    }
    .ico {
      display: inline-block;
      width: 50px;
      height: 50px;
      margin-top: 10px;
    }
  }
}
.select2-results__option {
  font-size: 14px;
}
.box_booking {
  position: relative;
  @extend .after_clear;
  margin-bottom: 45px;
  .item_booking {
    border: 1px solid #d8d8d8;
    .top_book {
      padding: 12px 40px;
      @extend .after_clear;
      background: $c_adv;
      h6 {
        font-size: 16px;
        text-transform: uppercase;
        color: #fff;
        font-family: $f_lato;
      }
    }
    .text_booking {
      padding: 30px 40px 47px;
      h1 {
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 15px;
      }
      .tgl {
        font-size: 20px;
        color: $c_333;
        margin-bottom: 20px;
        display: block;
      }
      p {
        font-family: $f_lato;
        color: $c_666;
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 30px;
      }
      .link {
        @extend .after_clear;
        margin-top: 30px;
        a {
          display: inline-block;
          &:first-child {
            margin-right: 20px;
          }
          &:last-child {
            margin-right: 0px;
          }
          &.back {
            font-family: $f_lato;
            color: $c_333;
            font-weight: 700;
            margin-top: 25px;
            font-size: 18px;
            @include transition(color ease-out 0.25s);
            &:hover {
              color: $c_adv;
              @include transition(color ease-out 0.25s);
            }
          }
        }
      }
      hr {
        border: none;
        border-bottom: 1px solid #d8d8d8;
        margin: 30px 0;
      }
      .ket_detail {
        .col {
          @extend .after_clear;
          margin-bottom: 20px;
          label {
            float: left;
            font-size: 18px;
            font-family: $f_lato;
            color: $c_999;
            display: inline-block;
            line-height: 30px;
          }
          p,
          ul {
            display: block;
            margin-left: 200px;
          }
          p {
            font-size: 18px;
            font-weight: 600;
            line-height: 30px;
            margin-bottom: 0;
          }
          ul {
            li {
              font-size: 18px;
              font-weight: 600;
              line-height: 30px;
              color: $c_666;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
  &.paid {
    .item_booking {
      .top_book {
        background: #2c2c2c;
      }
    }
  }
  &.done {
    .item_booking {
      .top_book {
        background: #1695c4;
      }
    }
  }
}

.datepicker-dropdown {
  position: absolute;
  background: #fff;
  z-index: 5;
  padding: 10px;
  @include boxShadow(0 0 20px -4px rgba(0, 0, 0, 0.3));
  @include borderRadius(10px);
  display: none;
  td,
  th {
    width: 30px;
    height: 25px;
    vertical-align: middle;
  }
  &:before,
  &:after {
    left: 25px;
  }
  thead tr:first-child th:hover,
  tfoot tr:first-child th:hover {
    background: rgba(180, 51, 2, 0.4);
  }
  th {
    font-weight: 600;
  }
  th.switch {
    font-size: 14px;
    font-weight: 700;
    height: 30px;
  }
  th.prev,
  th.next {
    i {
      width: 14px;
      height: 8px;
      background: url("../images/material/arr_select_adv.png") no-repeat;
      display: inline-block;
      @include transform(rotate(90deg));
    }
  }
  th.next {
    i {
      @include transform(rotate(-90deg));
    }
  }
  table tr {
    td.day:hover,
    td span:hover {
      background: rgba(180, 51, 2, 0.4);
    }
    td.active.active,
    td.active.active:hover {
      background: $c_adv;
    }
    td span.active.active,
    td span.active.active:hover {
      background: $c_adv;
    }
  }
}

/* footer
----------------------------------------------------------------------------------------------*/
.floating-contact {
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  z-index: 10;
	transition: 0.2s all ease-out;
  a {
    display: block;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
		border-radius: 50%;
    img {
      width: 60px;
      height: 60px;
    }
		&:hover{
			transform: scale(0.9);
		}
  }
}
footer {
  .top_foot {
    background: #f3f3f3;
    padding: 75px 0 60px;
    .wrapper {
      display: flex;
    }
    .box {
      float: left;
      &:nth-of-type(1) {
        width: 315px;
        margin-right: 90px;
        p {
          font-size: 14px;
          font-weight: 700;
          color: $c_666;
          font-family: $f_lato;
          line-height: 24px;
          margin: 0;
        }
      }
    }
    .mnu_foot {
      max-width: 790px;
      ul {
        @extend .after_clear;
        li {
          display: inline-block;
          width: 230px;
          margin-bottom: 5px;
          margin-right: 35px;
          &:nth-child(3n) {
            margin-right: 0;
          }
          a {
            font-size: 14px;
            text-transform: uppercase;
            color: #555;
            padding: 5px;
            display: inline-block;
            @include transition(color ease-out 0.25s);
            &:hover {
              @include transition(color ease-out 0.25s);
              color: $c_adv;
            }
          }
        }
      }
    }
    .follow {
      h6 {
        font-size: 14px;
        text-transform: uppercase;
        margin: 5px 0 15px;
      }
      .sos {
        margin-bottom: 20px;
        a {
          display: inline-block;
          width: 30px;
          height: 30px;
          font-size: 0;
          vertical-align: top;
          margin-right: 6px;
          &:hover {
            background-position: bottom center !important;
          }
        }
      }
      .ico_phone {
        padding-left: 40px;
        background: url("../images/material/phone_ico.png") no-repeat left
          center;
        line-height: 27px;
        font-size: 18px;
        color: #555;
        font-family: $f_lato;
        font-weight: 700;
        margin-bottom: 0;
      }
    }
  }
  .btm_foot {
    background: #2c2c2c;
    height: 100px;
    @include boxSizing(border-box);
    padding-top: 45px;
    .left {
      p {
        color: #acacac;
        margin: 0;
        font-family: $f_lato;
        font-size: 14px;
        a {
          color: #acacac;
          @include transition(color ease-out 0.25s);
          &:hover {
            color: #fff;
            @include transition(color ease-out 0.25s);
          }
        }
      }
    }
    .right {
      a {
        color: #acacac;
        margin: 0;
        font-family: $f_lato;
        font-size: 14px;
        @include transition(color ease-out 0.25s);
        padding-right: 15px;
        margin-right: 20px;
        border-right: 1px solid #acacac;
        &:hover {
          @include transition(color ease-out 0.25s);
          color: #fff;
        }
        &:last-child {
          padding-right: 0;
          border-right: none;
          margin-right: 0;
        }
      }
    }
  }
}

/* popup
----------------------------------------------------------------------------------------------*/

.wrap_popup {
  position: fixed;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999999;
  display: none;
  overflow-y: auto;
  .pop_inner {
    position: absolute;
    display: table;
    height: 100%;
    width: 100%;
    .overlay_pop {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 7;
    }
    .box_pop {
      display: table-cell;
      vertical-align: middle;
    }
    .content_pop {
      margin: 3% auto;
      position: relative;
      z-index: 8;
      width: 615px;
      padding: 0;
    }
  }
  &.wrap_order {
    .pop_inner {
      .content_pop {
        background: #f2f2f2;
        width: 420px;
        @include borderRadius(5px);
        min-height: 640px;
        @include borderRadius(7px);
      }
    }
  }
}
#pop_hotel {
  .pop_inner {
    .overlay_pop {
    }
    .content_pop {
      margin: 3% auto;
      position: relative;
      z-index: 8;
      width: 1300px;
      padding: 45px 60px 50px;
      background: #fff;
      @include boxSizing(border-box);
      .close {
        width: 45px;
        height: 45px;
        background: $c_adv;
        position: absolute;
        top: 0;
        right: 0;
        span {
          width: 26px;
          height: 3px;
          background: #fff;
          display: inline-block;
          @include transform(rotate(45deg));
          top: 22px;
          position: absolute;
          left: 10px;
          &:nth-child(2) {
            @include transform(rotate(-45deg));
          }
        }
      }
      .top_popup {
        text-align: center;
        margin-bottom: 50px;
        h3 {
          font-size: 26px;
          color: #333;
          font-weight: 600;
          margin-bottom: 10px;
        }
        p {
          font-size: 13px;
          font-family: $f_lato;
        }
        .star {
          span {
            display: inline-block;
            width: 14px;
            height: 13px;
            background: url("../images/material/star_detail.png") no-repeat top
              center;
            margin-bottom: 15px;
            &.act {
              background-position: bottom center;
            }
          }
        }
      }
      .content_resort {
        .left {
          width: 45%;
          h4 {
            font-size: 18px;
            font-weight: 700;
            color: #333;
            margin-bottom: 15px;
          }
          p {
            font-size: 18px;
            font-family: $f_lato;
            color: #666;
            line-height: 26px;
          }
          ul {
            li {
              font-size: 18px;
              font-family: $f_lato;
              color: #666;
              line-height: 26px;
              padding-left: 20px;
              position: relative;
              &:before {
                content: "-";
                font-size: 18px;
                color: #666;
                position: absolute;
                left: 0;
                top: 0;
                display: block;
              }
            }
          }
        }
        .right {
          width: 50%;
        }
        .list-img {
          .img {
            float: left;
            width: 32%;
            margin-right: 2%;
            margin-bottom: 10px;
            img {
              display: block;
              width: 100%;
            }
            &.big {
              width: 100%;
            }
            &:last-of-type {
              margin-right: 0;
            }
          }
          .slide_big {
            margin-bottom: 15px;
            .slide {
              img {
                display: block;
                width: 100%;
              }
            }
          }
          .thumb_slide {
            .thumb {
              margin: 0 6px;
              cursor: pointer;
              position: relative;
              &:after {
                content: "";
                background: rgba(0, 0, 0, 0.6);
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                display: block;
              }
              &.slick-current {
                &:after {
                  opacity: 0;
                }
              }
            }
            .slick-arrow {
              font-size: 0;
              border: none;
              width: 10px;
              height: 10px;
              padding: 0;
              border-top: 5px solid transparent;
              border-bottom: 5px solid transparent;
              border-right: 10px solid #333;
              background: none;
              position: absolute;
              z-index: 2;
              left: -12px;
              cursor: pointer;
              margin: auto;
              top: 0;
              bottom: 0;
              &.slick-next {
                left: auto;
                right: -12px;
                @include transform(rotate(180deg));
              }
              &.slick-disabled {
                border-right-color: #ccc;
              }
            }
          }
        }
        &:after {
          content: "";
          clear: both;
          display: block;
        }
      }
    }
  }
}

.top_box {
  @extend .after_clear;
  height: 60px;
  background: #fff;
  @include borderRadius(5px 5px 0 0);
  .left {
    @extend .after_clear;
  }
  .close_box {
    width: 22px;
    height: 22px;
    display: block;
    position: relative;
    float: left;
    margin: 18px 15px;
    span {
      height: 2px;
      width: 100%;
      background: #d8d8d8;
      position: absolute;
      top: 9px;
      z-index: 2;
      display: block;
      left: 0;
      &:nth-child(1) {
        @include transform(rotate(45deg));
      }
      &:nth-child(2) {
        @include transform(rotate(-45deg));
      }
    }
    &:hover {
      span {
        background: $c_adv;
      }
    }
  }
  .bk_box {
    span {
      width: 70%;
      top: 17px;
      left: 3px;
      &:nth-child(2) {
        top: 8px;
      }
    }
  }
  .logo_pop {
    float: left;
    padding: 9px 0 9px 15px;
    margin: 11px 0 0;
    border-left: 1px solid #d8d8d8;
  }
  .right {
    span {
      font-size: 13px;
      color: $c_adv;
      font-weight: 700;
      display: block;
      line-height: 60px;
      padding-right: 20px;
    }
  }
}
.amount {
  @extend .after_clear;
  background: #fff;
  padding: 15px 10px 15px 20px;
  border-left: 2px solid $c_adv;
  border-bottom: 2px solid $c_adv;
  margin: 12px 10px;
  label {
    font-size: 14px;
    float: left;
    color: $c_adv;
    font-family: $f_lato;
  }
  .total_amount {
    display: block;
    margin-left: 150px;
    text-align: right;
    font-size: 30px;
    color: $c_adv;
    font-family: $f_lato;
    span {
      font-size: 20px;
      display: inline-block;
      vertical-align: top;
      margin-right: 5px;
    }
  }
}

.detail_list {
  background: #fff;
  padding: 15px 10px 15px 20px;
  margin: 12px 10px;
  h4 {
    font-size: 14px;
    color: $c_333;
    padding-bottom: 10px;
    border-bottom: 3px solid $c_adv;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
  }
  table {
    width: 100%;
    td,
    th {
      font-size: 14px;
      color: $c_333;
      padding: 15px 5px;
      &:first-child {
        text-align: left;
        padding-left: 0;
      }
      &:last-child {
        text-align: right;
        padding-right: 0;
      }
    }
    th {
      font-size: 13px;
      color: $c_999;
      padding: 10px 0;
      border-bottom: 1px solid #d8d8d8;
    }
    tbody {
      tr:first-child {
        td {
          padding-top: 25px;
        }
      }
    }
  }
  .list {
    .item {
      @extend .after_clear;
      padding: 12px 10px 12px 20px;
      border-bottom: 1px solid #d8d8d8;
      margin: 0 -10px 0 -20px;
      span {
        width: 35px;
        height: 50px;
        float: left;
        display: block;
      }
      .txt_pay {
        display: block;
        margin-left: 50px;
        padding-right: 30px;
        margin-top: 10px;
        p {
          margin-bottom: 0;
        }
        h6 {
          font-weight: 700;
        }
      }
      a {
        color: $c_666;
      }
      &:last-child {
        border-bottom: none;
        margin-bottom: -10px;
      }
    }
  }
}
.continue {
  position: absolute;
  bottom: 0;
  display: block;
  background: #b43302 url("../images/material/ico_continue.png") no-repeat 93%
    center;
  color: #fff;
  left: 0;
  right: 0;
  padding: 20px 25px;
  font-size: 18px;
  font-weight: 700;
  @include borderRadius(0 0 6px 6px);
  @include transition(all ease-out 0.3s);
  &:hover {
    color: #fff;
    background-color: #952c05;
    @include transition(all ease-out 0.3s);
  }
}
